// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-a-la-carte-tsx": () => import("./../../../src/pages/a-la-carte.tsx" /* webpackChunkName: "component---src-pages-a-la-carte-tsx" */),
  "component---src-pages-book-tsx": () => import("./../../../src/pages/book.tsx" /* webpackChunkName: "component---src-pages-book-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-dimsum-tsx": () => import("./../../../src/pages/dimsum.tsx" /* webpackChunkName: "component---src-pages-dimsum-tsx" */),
  "component---src-pages-eat-all-you-like-tsx": () => import("./../../../src/pages/eat-all-you-like.tsx" /* webpackChunkName: "component---src-pages-eat-all-you-like-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-menus-tsx": () => import("./../../../src/pages/menus.tsx" /* webpackChunkName: "component---src-pages-menus-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-traditional-tsx": () => import("./../../../src/pages/traditional.tsx" /* webpackChunkName: "component---src-pages-traditional-tsx" */)
}

